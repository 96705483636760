import { AnyAction } from 'redux';
import { put, select, call, all, delay } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { getCompanyId } from '@/store/reducers/newAuth';
import { confirmSaga } from './confirm';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { definitions, operations } from '@/apitypes';
import qs from 'qs';
import { ApplicationState } from '@/store';
import { DEFAULT_SCHEDULER_COLOR } from '@/utils/constants';
import { Resource } from '@/types';

export function* resourceTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/resourcetypes', {
            params: {
                Active: true,
                IncludeResources: true,
                ...payload,
            },
        });

        yield put({ type: actions.FETCH_RESOURCE_TYPES.SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: actions.FETCH_RESOURCE_TYPES.FAILURE, payload: error });
    }
}

const tableResourcesToIds = (resources: any[]) =>
    resources.reduce(
        (acc, resource) => (resource.Selected ? [...acc, { ...resource, Priority: 99 }] : acc),
        []
    );

export function* createResourceType({ payload }: AnyAction) {
    const create = actions.CREATE_RESOURCE_TYPE;
    try {
        const CompanyId: string = yield select(getCompanyId);
        const location: Location = yield select((s: ApplicationState) => s.router.location);
        const response: AxiosResponse = yield apiClient.post('/resourcetypes', {
            ...payload,
            Resources: tableResourcesToIds(payload.Resources),
            CompanyId,
        });

        yield put(create.success(response.data));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editResourceType({ payload, meta }: AnyAction) {
    const edit = actions.EDIT_RESOURCE_TYPE;
    try {
        const response: AxiosResponse = yield apiClient.put(`/resourcetypes/${payload.Id}`, {
            ...payload,
            Resources: tableResourcesToIds(payload.Resources),
            Id: undefined,
        });

        yield put(edit.success(response));

        // Reload the list (for instance, after reactivate  a resource)
        if (meta && meta.reloadListOnSuccess) {
            const resourcesState: RootState['resourceTypes'] = yield select((state) => state.resourceTypes);
            // @ts-ignore
            const { requestActionPayload } = resourcesState;
            yield put(actions.FETCH_RESOURCE_TYPES.request(requestActionPayload));
        }
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* createResourceTypeWizard({ payload }: AnyAction) {
    const create = actions.CREATE_RESOURCE_TYPE_WIZARD;
    const location: Location = yield select((s: ApplicationState) => s.router.location);
    const createdResources: Resource[] = yield select((s: ApplicationState) => s.resourceTypes.created);
    try {
        const CompanyId: string = yield select(getCompanyId);

        const resources: { Name: string }[] = payload.resourceWizard
            ? payload.resourceWizard
                .filter((r: any) => r.Name)
                .map((r: any) => ({
                    Name: r.Name,
                    CompanyId,
                    Color: DEFAULT_SCHEDULER_COLOR
                }))
            : [];

        const response: AxiosResponse = yield apiClient.post('/resourcetypes', {
            Name: payload.Name,
            Description: payload.Description,
            CompanyId,
            Resources: createdResources.filter(cr => resources.map(r => r.Name).includes(cr.Name)),
        } as operations['CreateResourceType_Post']['parameters']['formData']);

        yield put(create.success(response.data));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        } else {
            yield put(push('/resources'));
        }
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* deleteResourceType({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/resourcetypes/${payload}`);

            yield put(actions.DELETE_RESOURCE_TYPE.success(response));
            yield put(push('/resources'));
        } catch (error) {
            yield put(actions.DELETE_RESOURCE_TYPE.failure(error));
        }
    }
}
