import { createSlice } from '@reduxjs/toolkit';
import { SHOW_MODAL, HIDE_MODAL } from '@/store/actions';

const modalSlice = createSlice({
    name: 'appModal',
    initialState: {
        open: false
    },
    reducers: { },
    extraReducers: (builder) => {
        builder.addMatcher(SHOW_MODAL.match, (state) => {
            state.open = true;
        });

        builder.addMatcher(HIDE_MODAL.match, (state) => {
            state.open = false;
        });
    }
});

export default modalSlice;