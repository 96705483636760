import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    Button,
    Divider,
    Grid,
    Header,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    Icon,
    Message
} from 'semantic-ui-react';
import Modal from '@/components/Modal';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { ApplicationState } from '@/store';
import { format, toDate } from '@/utils/datetime';
import { Box, Flex } from 'grid-styled';
import { formatToLocalCurrencyValue } from '@/utils/common';
import classnames from 'classnames/bind';
import * as actions from '@/store/actions';

import styles from './CreateInvoice.module.css';
import { useGetEaccountingPaymentTermsQuery } from '@/services/bmApi';

const cx = classnames.bind(styles);

interface Props extends LocalizeContextProps, ConnectedProps<typeof connector> {}

const CreateInvoiceSuccessModal: React.FC<Props> = ({ translate, invoice, invoiceUrl ,dispatch }) => {
    const paymentTerms = useGetEaccountingPaymentTermsQuery();
    
    return (
        <Modal className={cx('create-invoice-success-modal')} open closeOnDimmerClick={false}>
            <Modal.Content>
                {invoice ? (
                    <>
                        <Box mb="4">
                            <Header className={cx('invoice-header')}>
                                <Flex alignItems="center">
                                    <Icon name="checkmark" color="green" size="big" />
                                    <Box>
                                        {translate('CreateInvoiceSuccessModal.header', {
                                            invoiceNumber: invoice.InvoiceNumber,
                                        })}
                                    </Box>
                                </Flex>
                            </Header>
                            <Message
                                content={translate('CreateInvoiceSuccessModal.info')}
                                info
                                icon={'info'}
                            />
                        </Box>
                        <Segment>
                            <Box mb="4">
                                <Grid>
                                    <Grid.Column computer={6} mobile={16}>
                                        <Box>
                                            <strong>
                                                {translate('CreateInvoiceSuccessModal.billedTo')}
                                            </strong>
                                        </Box>
                                        <Box>{invoice.InvoiceCustomerName}</Box>
                                        {invoice.InvoiceAddress ? (
                                            <Box>{invoice.InvoiceAddress.InvoiceAddress1}</Box>
                                        ) : null}
                                        {invoice.InvoiceAddress ? (
                                            <Box mb="2">{`${invoice.InvoiceAddress.InvoiceCity} ${invoice.InvoiceAddress.InvoicePostalCode}, ${invoice.InvoiceAddress.InvoiceCountryCode}`}</Box>
                                        ) : null}
                                        <Box mb="2">
                                            <Box>
                                                <strong>
                                                    {translate(
                                                        'CreateInvoiceSuccessModal.CustomerEmail'
                                                    )}
                                                    :{' '}
                                                </strong>
                                                {invoice.CustomerEmail}
                                            </Box>
                                        </Box>
                                    </Grid.Column>
                                    <Grid.Column stretched computer={6} mobile={16}>
                                        <Box>
                                            <strong>
                                                {translate(
                                                    'CreateInvoiceSuccessModal.InvoiceNumber'
                                                )}
                                            </strong>
                                        </Box>
                                        <Box mb="3">{invoice.InvoiceNumber}</Box>
                                        <Box>
                                            <strong>
                                                {translate('CreateInvoiceSuccessModal.CreatedDate')}
                                            </strong>
                                        </Box>
                                        <Box>{format(invoice.CreatedDate, 'P')}</Box>
                                    </Grid.Column>
                                    <Grid.Column
                                        verticalAlign="middle"
                                        textAlign="center"
                                        computer={4}
                                        mobile={16}
                                        className={cx('big-price')}
                                    >
                                        <Box className={cx('key')}>
                                            {translate('CreateInvoiceSuccessModal.TotalAmount')}
                                        </Box>
                                        <Box className={cx('value')}>
                                            <strong>
                                                {formatToLocalCurrencyValue(
                                                    invoice.TotalAmount,
                                                    invoice.PriceSign
                                                )}
                                            </strong>
                                        </Box>
                                    </Grid.Column>
                                </Grid>
                            </Box>
                            <Divider />
                            <Grid>
                                <Grid.Column computer={6}>
                                    {paymentTerms.data?.find(
                                        (p) => p.Id === invoice.TermsOfPaymentId
                                    ) ? (
                                        <Box mb="2">
                                            <Box>
                                                <strong>
                                                    {translate(
                                                        'CreateInvoiceSuccessModal.TermsOfPayment.text'
                                                    )}
                                                </strong>
                                            </Box>
                                            <Box>
                                                {
                                                    paymentTerms.data?.find(
                                                        (p) => p.Id === invoice.TermsOfPaymentId
                                                    )?.Name
                                                }
                                            </Box>
                                        </Box>
                                    ) : null}

                                    {invoice?.InvoiceAddress?.InvoiceAddress2 ? (
                                        <Box mb="2">
                                            <Box>
                                                <strong>
                                                    {translate(
                                                        'CreateInvoiceSuccessModal.InvoiceAddress2'
                                                    )}
                                                </strong>
                                            </Box>
                                            <Box>{invoice.InvoiceAddress.InvoiceAddress2}</Box>
                                        </Box>
                                    ) : null}
                                    <Box mb="2">
                                        <Box>
                                            <strong>
                                                {translate(
                                                    'CreateInvoiceSuccessModal.PrivatePersonOrCompany'
                                                )}
                                            </strong>
                                        </Box>
                                        <Box>
                                            {invoice.CustomerIsPrivatePerson
                                                ? translate(
                                                      'CreateInvoiceSuccessModal.PrivatePerson'
                                                  )
                                                : translate('CreateInvoiceSuccessModal.Company')}
                                        </Box>
                                    </Box>
                                </Grid.Column>
                                <Grid.Column computer={6}>
                                    <Box mb="2">
                                        <Box>
                                            <strong>
                                                {translate('CreateInvoiceSuccessModal.DueDate')}
                                            </strong>
                                        </Box>
                                        <Box>{format(invoice.DueDate, 'PPp')}</Box>
                                    </Box>
                                    <Box mb="2">
                                        <Box>
                                            <strong>
                                                {translate(
                                                    'CreateInvoiceSuccessModal.PaymentStatus'
                                                )}
                                            </strong>
                                        </Box>
                                        <Box>{String(invoice.PaymentStatus)}</Box>
                                    </Box>
                                </Grid.Column>
                            </Grid>
                        </Segment>

                        <Segment>
                            <Table basic="very" className={cx('borderless-table')}>
                                <TableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="center">
                                            {translate('CreateInvoiceSuccessModal.Row.Text')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {translate('CreateInvoiceSuccessModal.Row.Quantity')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {translate('CreateInvoiceSuccessModal.Row.Price')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">
                                            {translate('CreateInvoiceSuccessModal.Row.Total')}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <TableBody>
                                    {invoice.Rows.map((row, i) =>
                                        row.IsTextRow ? (
                                            <Table.Row>
                                                <TableCell textAlign="center">{row.Text}</TableCell>
                                            </Table.Row>
                                        ) : (
                                            <Table.Row key={i}>
                                                <TableCell textAlign="center">{row.Text}</TableCell>
                                                <TableCell textAlign="center">
                                                    {row.Quantity}
                                                </TableCell>
                                                <TableCell textAlign="center">
                                                    {formatToLocalCurrencyValue(
                                                        row.UnitPrice,
                                                        invoice.PriceSign
                                                    )}
                                                </TableCell>
                                                <TableCell textAlign="center">
                                                    {formatToLocalCurrencyValue(
                                                        row.Quantity * row.UnitPrice,
                                                        invoice.PriceSign
                                                    )}
                                                </TableCell>
                                            </Table.Row>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Segment>

                        <Grid>
                            <Grid.Column computer={5} mobile={16} textAlign="right" floated="right">
                                <Segment>
                                    <Table basic="very" className={cx('borderless-table')}>
                                        <TableBody>
                                            <Table.Row>
                                                <Table.Cell
                                                    className={cx('text-transform-upper-case')}
                                                >
                                                    <strong>
                                                        {translate(
                                                            'CreateInvoiceSuccessModal.subTotal'
                                                        )}
                                                        :{' '}
                                                    </strong>
                                                </Table.Cell>
                                                <TableCell>
                                                    {formatToLocalCurrencyValue(
                                                        invoice.TotalAmount -
                                                            invoice.TotalVatAmount,
                                                        invoice.PriceSign
                                                    )}
                                                </TableCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell
                                                    className={cx('text-transform-upper-case')}
                                                >
                                                    <strong>
                                                        {translate(
                                                            'CreateInvoiceSuccessModal.TotalVatAmount'
                                                        )}
                                                        {invoice.VatSpecification[0].VatPercent
                                                            ? ` (${
                                                                  invoice.VatSpecification[0]
                                                                      .VatPercent * 100
                                                              }%)`
                                                            : null}
                                                        :{' '}
                                                    </strong>
                                                </Table.Cell>
                                                <TableCell>
                                                    {formatToLocalCurrencyValue(
                                                        invoice.TotalVatAmount,
                                                        invoice.PriceSign
                                                    )}
                                                </TableCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell
                                                    className={cx('text-transform-upper-case')}
                                                >
                                                    <strong>
                                                        {translate(
                                                            'CreateInvoiceSuccessModal.TotalAmount'
                                                        )}
                                                        :{' '}
                                                    </strong>
                                                </Table.Cell>
                                                <TableCell>
                                                    {formatToLocalCurrencyValue(
                                                        invoice.TotalAmount,
                                                        invoice.PriceSign
                                                    )}
                                                </TableCell>
                                            </Table.Row>
                                        </TableBody>
                                    </Table>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </>
                ) : null}
                <Box mt="3">
                    <Flex justifyContent="end">
                        <Button
                            content={translate('CreateInvoiceSuccessModal.OpenInvoiceUrl')}
                            onClick={(evt) => {
                                evt.preventDefault();
                                window.open(invoiceUrl);
                            }}
                        />

                        <Button
                            primary
                            content={translate('form.ok')}
                            onClick={(evt) => {
                                evt.preventDefault();
                                dispatch(actions.hideInvoiceSuccessModal());
                            }}
                        />
                    </Flex>
                </Box>
            </Modal.Content>
        </Modal>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    const { eaccounting } = state;

    return {
        invoice: eaccounting.createInvoice?.data?.Invoice,
        invoiceUrl: eaccounting.createInvoice?.data?.InvoiceUri
    };
};

const connector = connect(mapStateToProps);

export default connector(withLocalize(CreateInvoiceSuccessModal));
