import { createSlice, createListenerMiddleware, createAction, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { put, delay, call, take, race, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ApplicationState, TypedListener } from "@/store";
import * as actions from '@/store/actions';
import { IncentiveResponse, Incentive } from '@/types';
import bmApi from '@/services/bmApi';
import { apiClient } from '@/utils';
import { getCompanyId } from './newAuth';

interface Entity {
    incentives: IncentiveResponse['Incentives'],
    currentIncentive: Incentive | null;
    billingForm: boolean;
}
interface InitialState {
    entity: Entity | null;
    isLoading: boolean;
    error: any;
}

export const initialState: InitialState = {
    entity: null,
    error: null,
    isLoading: false,
};

// Middlewares
export const incentivesListener = createListenerMiddleware();
export const startIncentivesListener = incentivesListener.startListening as TypedListener;

// Sagas
export function* runIncentiveUpgradeAction(incentive: Incentive) {
    try {
        const companyId: string = yield select(getCompanyId);
        yield delay(incentive.InitialDelayInSeconds * 1000);

        yield put(setCurrentIncentive(incentive));

        apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
            CompanyId: companyId,
            IncentiveId: incentive.Id,
            EngagementType: 'Read'
        });

        const { ok, cancel } = yield race({
            ok: take(actions.CONFIRM_YES),
            cancel: take(actions.CONFIRM_NO),
        });

        
        if(ok) {
            apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                CompanyId: companyId,
                IncentiveId: incentive.Id,
                EngagementType: 'Engaged'
            });
            
            yield put(createCompanyLicense());

            const { licenseCreated, licenseCreationInterrupted } = yield race({
                licenseCreated: take(actions.CREATE_COMPANY_LICENSE.SUCCESS),
                licenseCreationInterrupted: take(actions.CONFIRM_NO),
            });

            if(licenseCreationInterrupted) {
                // @ts-ignore
                yield put(cancelIncentive());
                apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                    CompanyId: companyId,
                    IncentiveId: incentive.Id,
                    EngagementType: 'Cancelled'
                });
            }
            
            if(licenseCreated) {
                // @ts-ignore
                yield put(finishIncentive());
                apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                    CompanyId: companyId,
                    IncentiveId: incentive.Id,
                    EngagementType: 'Success'
                });
            }

            // TODO: add license created logic
        } else if (cancel) {
            // @ts-ignore
            yield put(cancelIncentive());
            apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                CompanyId: companyId,
                IncentiveId: incentive.Id,
                EngagementType: 'Cancelled'
            });
        }


    } catch (error) {
        // TODO: what to do if it errors out, should we do anything?
    }
}

export function* runInInformationAction(incentive: Incentive) {
    try {
        const companyId: string = yield select(getCompanyId);
        yield delay(incentive.InitialDelayInSeconds * 1000);

        yield put(setCurrentIncentive(incentive));

        apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
            CompanyId: companyId,
            IncentiveId: incentive.Id,
            EngagementType: 'Read'
        });

        const { ok, cancel } = yield race({
            ok: take(actions.CONFIRM_YES),
            cancel: take(actions.CONFIRM_NO),
        });

        apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
            CompanyId: companyId,
            IncentiveId: incentive.Id,
            EngagementType: 'Engaged'
        });

        if(ok) {
            // @ts-ignore
            yield put(finishIncentive())
            yield put(push(incentive.Action.Page));

            apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                CompanyId: companyId,
                IncentiveId: incentive.Id,
                EngagementType: 'Success'
            });
        } else if (cancel) {
            // @ts-ignore
            yield put(cancelIncentive());

            apiClient.post(`/company/${companyId}/incentives/${incentive.Id}`, {
                CompanyId: companyId,
                IncentiveId: incentive.Id,
                EngagementType: 'Cancelled'
            });
        }


    } catch (error) {
        // TODO: what to do if it errors out, should we do anything?
    }
}

export function* runIncentives(action: PayloadAction<Incentive[]>) {
    try {
        const incentives = action.payload ? [...action.payload] : [];
        const incentive = incentives.shift();
        
        // TODO: add all action types
        if(incentive && (incentive.Action.ActionType === 'Upgrade' || incentive.Action.ActionType === 'AddOn')) {
            yield call(runIncentiveUpgradeAction, incentive);
        } else if(incentive && incentive.Action.ActionType === 'Information') {
            yield call(runInInformationAction, incentive);
        }
    } catch (error) {
        console.error(error);
    }
}

// Slice
const incentivesSlice = createSlice({
    name: 'incentives',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addMatcher(setIncentives.match, (state, { payload }) => {
            if(!state.entity) {
                state.entity = { incentives: payload, billingForm: false, currentIncentive: null };
            } else {
                state.entity = {
                    ...state.entity,
                    incentives: payload
                }
            }
        });

        builder.addMatcher(setCurrentIncentive.match, (state, { payload }) => {
            if(!state.entity) {
                return;
            }

            state.entity.currentIncentive = payload;
            state.entity.incentives = state.entity.incentives.filter(incentive => incentive.Id !== payload.Id);
        });

        builder.addMatcher(cancelIncentive.fulfilled.match, (state, { payload }) => {
            if(!state.entity) {
                return;
            }

            state.entity.currentIncentive = null;
            state.entity.billingForm = false;
        });

        builder.addMatcher(finishIncentive.fulfilled.match, (state, { payload }) => {
            if(!state.entity) {
                return;
            }

            state.entity.currentIncentive = null;
            state.entity.billingForm = false;
        });

        builder.addMatcher(createCompanyLicense.match, (state, _) => {
            if(!state.entity) {
                return;
            }
            state.entity.billingForm = true;
        });
    }
});

// Actions
export const confirmActionOk = createAction(`${incentivesSlice.name}/CONFIRM_ACTION_OK`)
export const confirmActionCancel = createAction(`${incentivesSlice.name}/CONFIRM_ACTION_CANCEL`)
export const setCurrentIncentive = createAction(`${incentivesSlice.name}/SET_CURRENT_INCENTIVE`, (incentive: Incentive) => {
    return {
        payload: incentive
    }
});
export const setIncentives = createAction(`${incentivesSlice.name}/SET_INCENTIVES`, (incentive: Incentive[]) => {
    return {
        payload: incentive
    }
});
export const createCompanyLicense = createAction(`${incentivesSlice.name}/CREATE_COMPANY_LICENSE`);

// Thunks
export const cancelIncentive = createAsyncThunk<void, void, { state: ApplicationState }>(`${incentivesSlice.name}/CANCEL_INCENTIVE`, (_, thunkApi) => {
    const state = thunkApi.getState();
    
    const incentives = state.incentives.entity?.incentives || [];
    thunkApi.dispatch(setIncentives(incentives));

    thunkApi.fulfillWithValue(true);
});
export const finishIncentive = createAsyncThunk<void, void, { state: ApplicationState }>(`${incentivesSlice.name}/FINISH_INCENTIVE`, (_, thunkApi) => {
    const state = thunkApi.getState();
    
    const incentives = state.incentives.entity?.incentives || [];
    thunkApi.dispatch(setIncentives(incentives));

    thunkApi.fulfillWithValue(true);
});

// Listeners
startIncentivesListener({
    matcher: bmApi.endpoints.getIncentives.matchFulfilled,
    effect: (action, state) => {
        if(!state.getState().incentives.entity) {
            state.dispatch(setIncentives(action.payload.Incentives))
        }
    }
});

export default incentivesSlice;