import { delay, put, select, take, call} from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { push } from 'connected-react-router';
import qs from 'qs';
import { getTranslate } from 'react-localize-redux';
import { format } from '@/utils/datetime';
import isEmpty from 'lodash/isEmpty';
import { AnyAction } from 'redux';
import { CREDIT_CARD } from '@/utils/constants';

import { apiClient, throwSubmissionError } from '@/utils';
import * as actions from '@/store/actions';
import downloadPDF from '@/utils/downloadPDF';
import { AxiosResponse } from 'axios';
import { BillingInformation } from '@/types';
import { RootState } from 'MyTypes';
import keycloak from '@/keycloak';
import { ApplicationState } from '@/store';

export function* billingSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company');

        yield put(actions.FETCH_BILLING_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BILLING_SETTINGS.failure(error));
    }
}

export function* editBillingSettings(action: AnyAction) {
    try {
        const initialValues: { [key in string]: string} = yield select((state) => state.billing.settings.data);
        const callApi = isEmpty(initialValues) ? apiClient.post : apiClient.put;
        const response: AxiosResponse = yield callApi(`/billing/company`, action.payload.BillingInformation);
        const localizeState: RootState['localize'] = yield select((state) => state.localize);
        const translate = getTranslate(localizeState);

        if(action.payload.BillingInformation.BillingMethodId !== CREDIT_CARD) {
            yield put(actions.FETCH_BILLING_SETTINGS.request());
            yield put(actions.EDIT_BILLING_SETTINGS.success(response));
            
            const modalOpened: boolean = yield select(s => s.appModal.open);
            yield delay(200)
            if(modalOpened) {
                yield put(
                    Notifications.show(
                        {
                            title: translate('billing.makeSureToClickSave') as string
                        },
                        'info'
                    )
                );
            }
        } else {
            const modalOpened: boolean = yield select(s => s.appModal.open);
            yield put(actions.EDIT_BILLING_SETTINGS.success(response));

            // this is here because we want to make sure the form is saved
            yield take(actions.FETCH_CREDIT_CARD.SUCCESS);
            
            if(!modalOpened) {
                yield put(
                    push({
                        pathname: '/billing/add-credit-card',
                        search: qs.stringify(response.data),
                    })
                );
            }

            yield delay(200)
            if(modalOpened) {
                yield put(
                    Notifications.show(
                        {
                            title: translate('billing.makeSureToClickSave') as string
                        },
                        'info'
                    )
                );
            }
        }
    } catch (error) {
        yield put(actions.EDIT_BILLING_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchBillingMethods({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/methods', {
            params: { CountryId: payload || 'SE' },
        });

        yield put(actions.FETCH_BILLING_METHODS.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_BILLING_METHODS.failure(error));
    }
}

export function* fetchCompanyCreditCard({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company/creditcard', {
            params: { IsValid: true },
        });

        yield put(actions.FETCH_CREDIT_CARD.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CREDIT_CARD.failure(error));
    }
}

export function* fetchBillingToken({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/Token/GenerateRegisterDibsPaymentCardToken');

        yield put(actions.FETCH_BILLING_TOKEN.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BILLING_TOKEN.failure(error));
    }
}

export function* fetchInvoices({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company/invoices', {
            params: {
                IncludeCurrencyInformation: true,
                IncludeInvoiceStatusInformation: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_INVOICES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_INVOICES.failure(error));
    }
}

export function* exportInvoice({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/billing/company/invoices/${payload.Id}`, {
            responseType: 'blob',
        });

        yield put(actions.FETCH_EXPORT_INVOICE.success());

        downloadPDF(response.data, `${payload.Id} - ${format(payload.InvoiceDate, 'Pp')}.pdf`);
    } catch (error) {
        yield put(actions.FETCH_EXPORT_INVOICE.failure(error));
    }
}

export function* watchBillingSettings() {
    while (true) {
        yield delay(2000);
        yield call(checkBillingSettings);
    }
}

export function* checkBillingSettings() {
    const billing: RootState['billing'] | undefined = yield select((s) => s?.billing);
    const modalOpened: RootState['billing'] | undefined = yield select((s:ApplicationState) => s.appModal.open);
    const router: RootState['router'] | undefined = yield select((s) => s?.router);
    const state: { [key in string]: any } = yield select((s) => s);
    
    const isLoading = Object.values(state).some((val) => val.isLoading === true);

    if(!billing?.settings.hasError && !billing?.settings.isLoaded && !billing?.settings.isLoading) {
        yield put(actions.FETCH_BILLING_SETTINGS.request());
    }

    const billingInfomodalForbidenLocation = 
        !router?.location.pathname.includes('/billing/settings') &&
        !router?.location.search.includes('billing-information') &&
        !router?.location.hash.includes('billing-information') &&
        !router?.location.pathname.includes('/account')

    if (
        keycloak &&
        keycloak.token && 
        billing?.settings?.hasError &&
        billingInfomodalForbidenLocation &&
        !isLoading &&
        !modalOpened
    ) {
        try {
            if (billing?.settings?.hasError) {
                yield put({ type: actions.SHOW_BILLING_INFO_MODAL });
            }

            yield take(actions.EDIT_BILLING_SETTINGS.SUCCESS);
            yield put({ type: actions.HIDE_BILLING_INFO_MODAL });
            yield take(actions.FETCH_COMPANY_LICENSES.SUCCESS);
            // yield put(push('/bookings'));
        } catch (error) {
            // we don't care about the error
        }
    }
}