import React, { Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Accordion, Sidebar, Menu, Icon, Label, AccordionTitleProps } from 'semantic-ui-react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import getNav from './_nav';
import { EntityState, Company, EaccountingSettingsResponse } from '@/types';
import { ApplicationState } from '@/store';
import bmApiService from '@/services/bmApi';

interface OuterProps {
    toggleSidebarOpen: (e: React.MouseEvent) => void;
    visible?: boolean;
    showSidebarToggle?: boolean;
    onItemClick?: (item: any) => void;
}

interface Props extends OuterProps, LocalizeContextProps, RouteComponentProps {
    company: EntityState<Company>;
}

interface State {
    activeIndex: number;
}

class AppSidebar extends Component<Props, State> {
    state = { activeIndex: 0 };

    handleDropdownClick = (e: React.MouseEvent, titleProps: AccordionTitleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        // @ts-ignore
        this.setState({ activeIndex: newIndex });
    };

    handleGoToHomepage = (e: React.MouseEvent) => {
        e.preventDefault();
        const { company } = this.props;
        if (company.data.Id) {
            window.open(`https://${company.data.SitePath}.bokamera.se/`, '_blank');
        }
    };

 

    handleGoToConfigurator = (e: React.MouseEvent) => {
        e.preventDefault();
        const { company, activeLanguage } = this.props;

        if (company.data.Id) {
            window.open(
                'https://configurator.bookmore.com' +
                    `?company=${company.data.Id}&language=${activeLanguage.code}`,
                '_blank'
            );
        }
    };

    handleItemClick = (item: any) => (e: React.MouseEvent) => {
        if (item.onClick) {
            e.preventDefault();
            item.onClick(e);
        }
        if (this.props.onItemClick) {
            this.props.onItemClick(item);
        }
    };

    render() {
        const { translate } = this.props;

        // simple wrapper for nav-title item
        const wrapper = (item: any) => {
            return item.wrapper && item.wrapper.element
                ? React.createElement(
                      item.wrapper.element,
                      item.wrapper.attributes,
                      translate(item.name)
                  )
                : translate(item.name);
        };

        // nav list section title
        const title = (title: any, key: number) => {
            return <li key={key}>{wrapper(title)} </li>;
        };

        // nav list divider
        const divider = (divider: any, key: number) => <li key={key} className="divider" />;

        // nav item with nav link
        const navItem = (item: any, key: number) => {
            const onClick = this.handleItemClick(item);

            const { isExternal } = item;

            return (
                <Menu.Item
                    key={key}
                    as={isExternal ? 'a' : NavLink}
                    to={isExternal ? undefined : item.url}
                    href={isExternal ? item.url : undefined}
                    target={isExternal ? '_blank' : undefined}
                    onClick={onClick}
                >
                    {item.icon && <Icon name={item.icon} />} {translate(item.name)}
                </Menu.Item>
            );
        };

        // nav dropdown
        const navDropdown = (item: any, index: number) => {
            const pathname = this.props.location.pathname;
            const isGroupActive = item.children.some((i: any) => {                
                // single level routes matching
                if(i.url?.includes(pathname)) {
                    return true;
                } else if (
                    // nested routes matching
                    pathname.split('/').filter(p => p).some(p=> i.url.includes(p)) &&
                    i?.url.split('/')[1] === pathname.split('/')[1]
                ) {
                    return true;
                }

                return false;
            });
            const isIndexActive = this.state.activeIndex === index || isGroupActive;

            return (
                <Menu.Item key={index} className={`${isGroupActive ? 'active-group' : ''}`}>
                    <Accordion.Title
                        active={isIndexActive}
                        index={index}
                        onClick={this.handleDropdownClick}
                        content={
                            <React.Fragment>
                                {item.icon ? <Icon name={item.icon} /> : null} {translate(item.name)}{' '}
                                {item.label && <Label color="teal">{item.label}</Label>}
                            </React.Fragment>
                        }
                    />
                    <Accordion.Content
                        active={isIndexActive}
                        content={navList(item.children)}
                    />
                </Menu.Item>
            );
        };

        // nav link
        const navLink = (item: any, idx: number) =>
            item.title
                ? title(item, idx)
                : item.divider
                ? divider(item, idx)
                : item.children
                ? navDropdown(item, idx)
                : navItem(item, idx);

        // nav list
        const navList = (items: any[]) => {
            return items.map((item, index) => navLink(item, index));
        };

        // sidebar-nav root
        return (
            <Sidebar
                as={Menu}
                className="accordion"
                animation="overlay"
                vertical
                visible={this.props.visible}
                style={{ top: '69px' }}
            >
                
                <Accordion style={{ paddingBottom: 100 }} icon='down'>
                    {navList(
                        getNav({
                            goToHomepage: this.handleGoToHomepage,
                            goToConfigurator: this.handleGoToConfigurator
                        })
                    )}
                </Accordion>
            </Sidebar>
        );
    }
}

export default compose<React.ComponentType<OuterProps>>(
    withLocalize,
    withRouter,
    connect((state: ApplicationState) => {
        const { company } = state;
        
        return {
            company
        };
    })
)(AppSidebar);
