import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import Modal from '@/components/Modal';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { BillingModalData } from '@/types';
import BillingSettings from '../BillingInformationSettings';
import styles from './Billing.module.css';

let cx = classNames.bind(styles);

interface Props extends LocalizeContextProps {
    dispatch: Dispatch;
}

interface State {
}

class BillingModal extends Component<Props, State> {

    render() {
        return (
            <Modal className={cx('wrapper')} open size="large" closeOnDimmerClick={false}>
                <Modal.Content>
                    <Message 
                    info
                    icon="info"
                    >
                        <Message.Header>
                            <Translate id="BillingSettingsModal.messageHeader" />
                        </Message.Header>
                        <Message.Content>
                        <Translate id="BillingSettingsModal.messageContent" />
                        </Message.Content>
                    </Message>
                    <BillingSettings autofill />
                </Modal.Content>
            </Modal>
        );
    }
}

export default connect()(withLocalize(BillingModal));
