import React from 'react';
import { FieldArray, FieldState, WrappedFieldArrayProps } from 'redux-form';
import { Grid } from 'semantic-ui-react';

import CustomFieldTextBox from './CustomFieldTextBox';
import CustomFieldDropDown from './CustomFieldDropDown';

interface WrappedProps extends WrappedFieldArrayProps {
    layout: any;
    fieldsStates?: FieldState[]
}

const renderCustomFields: React.ComponentType<WrappedProps> = ({ fields, layout, fieldsStates = [] }) => (
    <>
        {fields.map((fieldName, index) => {
            const fieldData = fields.get(index).meta || {};

            let component = null;

            if (fieldData.DataType === 'TextBox') {
                component = (
                    <CustomFieldTextBox
                        key={index}
                        name={fieldName}
                        customField={fieldData}
                        withLabel={!layout}
                        prepopulate={fieldsStates.length === 0 || !fieldsStates[index] }
                    />
                );
            } else if (fieldData.DataType === 'DropDown') {
                component = (
                    <CustomFieldDropDown
                        key={index}
                        name={fieldName}
                        customField={fieldData}
                        withLabel={!layout}
                    />
                );
            } else {
                component = (
                    <CustomFieldTextBox
                        key={index}
                        name={fieldName}
                        customField={fieldData}
                        withLabel={!layout}
                        prepopulate={fieldsStates.length === 0 || !fieldsStates[index] }
                    />
                );
            }

            return component;
        })}
    </>
);

type Props = {
    name: string;
    fieldsStates?: FieldState[];
    layout?: {
        label: Object;
        control: Object;
    };
};

const CustomFields: React.FC<Props> = (props) => {
    // @ts-ignore
    return <FieldArray {...props} component={renderCustomFields} />;
};

export default CustomFields;
