import axios, { AxiosError } from 'axios';
import { replace } from 'connected-react-router';
import qs from 'qs';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { store } from '../store';
import * as actions from '../store/actions';
import { getCurrentLanguageCode, getXlanguageCode } from './i18n';
import keycloak from '@/keycloak';
import { REFRESH_TOKEN_INTERVAL } from '@/keycloak';
import { apiClient } from '.';
import { generateContentForModals } from './generateContentForModals';
import { getTranslate } from 'react-localize-redux';
import { getFullLanguageCode, getTimeZoneFromLanguageCode } from './common';
import { omit } from 'lodash';
import { ADD_OR_CONTINUE_TRIAL_CLICKED } from './constants';

export const EXTRA_LICENSE_NEEDED_STATUS = 402;
export const LICENSE_NOT_ALLOWED_STATUS = 426;
export const INVALID_ROLE_STATUS = 403;

const UNAUTHORISED_ENDPOINTS = [
    {
        url: '/users/forgotpassword',
        method: 'POST',
    },
    {
        url: '/users',
        method: 'DELETE',
    },
];

const client = axios.create({
    baseURL:  process.env.REACT_APP_API_BASE_URL,
    responseType: 'json',
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

client.interceptors.request.use(
    function (conf) {
        const language = getCurrentLanguageCode();
        const timezone = getTimeZoneFromLanguageCode(language);

        store.dispatch(showLoading());
        let headers: any = {
            Accept: 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': getXlanguageCode(),
            'Timezone': timezone
        };

        if (keycloak && keycloak.refreshToken && keycloak.token) {
            headers['x-ss-id'] = keycloak.sessionId;
            headers['Authorization'] = 'Bearer ' + keycloak.token;
        }

        if(conf?.params?.afterSuccess) {
            conf.params = omit(conf.params, 'afterSuccess');
        }

        return { ...conf, headers };
    },
    function (error) {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response) => response,
    function (error: AxiosError & { config: AxiosError['config'] & {_retry?: boolean} }) {
        const originalRequest = error.config;
        store.dispatch(hideLoading());
        const localizeState = store.getState().localize;
        const pathname = store.getState().router.location.pathname;
        const translate = getTranslate(localizeState);
        
        if(error.response?.headers['x-correlation-id']) {
            store.dispatch(actions.addNonBlockingError({
                message: error.message,
                correlationId: error.response?.headers['x-correlation-id'],
                requestData: error.config.data,
                responseData: error.response.data,
                method: error.config.method!,
                url: error.config.url!
            }));
        }

        if(error.config.url && error.config.url.includes('chunk.js') ) {
            actions.addBlockingError(error);
        }

        if(error?.response?.status === 401  && !originalRequest._retry) {
            originalRequest._retry = true;
            keycloak.updateToken(REFRESH_TOKEN_INTERVAL).then(() => {
                client.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
                return apiClient(originalRequest);
            })
        }

        if(error?.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            keycloak.updateToken(REFRESH_TOKEN_INTERVAL).then(() => {
                client.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
                return apiClient(originalRequest);
            })
        }
        
        if (
            // For other pages than scheduler show licence modal
            !pathname.includes('/scheduler') &&
            (error?.response?.status === 426 || error?.response?.status === 402) &&
            !error?.response?.data?.CurrentTrialPeriod?.Active
        ) {
            const payload = generateContentForModals(
                error.response,
                originalRequest,
                store.getState()
            );

            store.dispatch({
                type: actions.SHOW_LICENSES_MODAL,
                payload,
                meta: { status: error.response.statusText },
            });
        } else if (
            // show trial modal
            !sessionStorage.getItem(ADD_OR_CONTINUE_TRIAL_CLICKED) &&
            pathname.includes('/scheduler') &&
            (error?.response?.status === 426 || error?.response?.status === 402)
        ) {
            store.dispatch({
                type: actions.SHOW_LICENSES_MODAL,
                payload: error.response.data,
                meta: { status: error.response.statusText },
            });
        }
        
        if(error?.response?.status === INVALID_ROLE_STATUS) {
            error.message = translate('apiMessages.invalidRole').toString();
        }

        return Promise.reject(error);
    }
);

export default client;
