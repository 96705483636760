import {
    createSlice,
    PayloadAction,
    Action,
    createListenerMiddleware,
    isAnyOf,
    TypedStartListening,
    Dispatch,
    createSelector,

} from '@reduxjs/toolkit';
import { createLoadingReducer, createEntityState } from './utils';
import * as actions from '../actions';
import reduceReducers from 'reduce-reducers';
import { Invoice, Price } from '@/types';
import { produce } from 'immer';
import bmApi from '@/services/bmApi';
import { setSubmitFailed, stopSubmit, startSubmit, setSubmitSucceeded } from 'redux-form';
import { convertErrorToFormError } from '@/utils';
import { ApplicationDispatch, ApplicationState, TypedListener } from '@/store';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import keycloak from '@/keycloak';
import { INITIAL_ROUTE_KEY, IS_LOADING_STORAGE_KEY, IS_LOGGED_IN_STORAGE_KEY } from '@/utils/constants';
import qs from 'qs';

const PLACEHOLDER_COMPANY_ID = '00000000-0000-0000-0000-000000000000';

type Realm = 'bookmore' | 'bookmore-admin'

export const realmParamsMapping: {[key in Realm]: number} =  {
    bookmore: 1,
    'bookmore-admin': 2
};


// TODO: register separate listeners instead of registering then all at once

export const authListener = createListenerMiddleware();

const startAuthListening = authListener.startListening as TypedListener;

const initialState = {
    ...createEntityState(),
    isLoggedIn: false,
}

export type InitialState = typeof initialState;

const authSlice = createSlice({
    name: 'auth',
    initialState,
    // @ts-ignore
    extraReducers: (builder, action) => {
        builder.addCase(actions.authSuccess, (state: InitialState) => {
            state.isLoading = false;
            state.isLoaded = true;
            state.isLoggedIn = true;
            state.hasError = false;
        });

        builder.addCase(actions.authLogout, (state: InitialState) => {
            sessionStorage.removeItem(IS_LOADING_STORAGE_KEY);
            localStorage.setItem(IS_LOGGED_IN_STORAGE_KEY, 'false');

            state.isLoading = false;
            state.isLoaded = true;
            state.isLoggedIn = false;
            state.hasError = false;
            state.error = undefined;
        });

        builder.addCase(actions.authFullLogout, (state: InitialState) => {
            sessionStorage.removeItem(IS_LOADING_STORAGE_KEY);
            localStorage.setItem(IS_LOGGED_IN_STORAGE_KEY, 'false');

            state.isLoading = false;
            state.isLoaded = true;
            state.isLoggedIn = false;
            state.hasError = false;
        });

        builder.addCase(actions.authFail, (state: InitialState, { payload }: PayloadAction) => {
            localStorage.setItem(IS_LOGGED_IN_STORAGE_KEY, 'false');
            sessionStorage.removeItem(IS_LOADING_STORAGE_KEY);

            state.isLoading = false;
            state.isLoaded = false;
            state.isLoggedIn = false;
            state.hasError = true;
            state.error = payload;
        });

        builder.addCase(actions.authoriseSuccess, (state: InitialState, action: PayloadAction) => {
            state.data = action.payload;
        });

        builder.addCase(actions.authoriseFail, (state: InitialState, action: PayloadAction) => {
            state.hasError = true;
            state.error = action.payload;
        })
    },
});

startAuthListening({
    matcher: isAnyOf(
        // @ts-ignore
        (a) => a.type === LOCATION_CHANGE,
        bmApi.endpoints.getUsers.matchFulfilled,
        bmApi.endpoints.getUsers.matchRejected,
        actions.authSuccess.match,
        actions.authLogout
    ),
    // @ts-ignore
    effect: async (action, api) => {
        const state = api.getState() as ApplicationState;

        const { isUninitialized: isUsersUninitialized } = bmApi.endpoints.getUsers.select()(state);

        if(actions.authLogout.match(action)) {
            sessionStorage.removeItem(INITIAL_ROUTE_KEY);
            keycloak.logout();
        }

        if(actions.authFail.match(action)) {
            sessionStorage.removeItem(INITIAL_ROUTE_KEY);
        }

        if(bmApi.endpoints.getUsers.matchFulfilled(action)) {
            if(!action.payload?.AdminProfile?.CompanyId) {
                const translate =  getTranslate(api.getState().localize);
                api.dispatch(actions.authoriseFail(translate('newAuth.noCompanyId') as string));
                api.dispatch(Notifications.show({ title: translate('newAuth.noCompanyId') as string }, 'error'));
            } else {
                api.dispatch(actions.authoriseSuccess({
                    Meta: {
                        ...action.payload.AdminProfile,
                        CompanyUsers: action.payload.CompanyCustomers,
                    },
                }));
            }
        }

        if(bmApi.endpoints.getUsers.matchRejected(action)) {
            api.dispatch(actions.authoriseFail(action.payload));
        }

        if(actions.authSuccess.match(action) && isUsersUninitialized) {
            api.dispatch(bmApi.endpoints.getUsers.initiate());
            
            const initialRoute = sessionStorage.getItem(INITIAL_ROUTE_KEY)
            
            if(initialRoute) {
                api.dispatch(push(initialRoute));
                sessionStorage.removeItem(INITIAL_ROUTE_KEY);

            }
        }

        if(actions.authFail.match(action)) {
            api.dispatch(push('/login'));
        }

        if(action.type === LOCATION_CHANGE && action.payload.location.search.includes('redirectTo')) {
            const { redirectTo } =  qs.parse(action.payload.location.search, { ignoreQueryPrefix: true });
            api.dispatch(push(redirectTo as string))
        }

    },
});


export const getUserId = (state: ApplicationState) =>
    state.authenticate.data && state.authenticate.data.Meta ? state.authenticate.data.Meta?.Id : undefined;

export const getCompanyId = (state: ApplicationState) =>
    state.authenticate.data && state.authenticate.data.Meta ? state.authenticate.data.Meta?.CompanyId : undefined;

export const isAuthenticated = (state: ApplicationState) => state.authenticate.isLoggedIn && !state.authenticate.isLoading && !state.authenticate.hasError;
export const isAuthorised = (state: ApplicationState) => !!state.authenticate?.data?.Meta?.CompanyId;

export const selectAuthorised = createSelector(
    isAuthenticated,
    isAuthorised,
    (authenticated, authorised) => authenticated && authorised
);

export const isLoading = (state: ApplicationState) => {
    return state.authenticate.isLoading || sessionStorage.getItem(IS_LOADING_STORAGE_KEY)
    ? !!JSON.parse(sessionStorage.getItem(IS_LOADING_STORAGE_KEY) || '') : false
};

export const getUsername =  (state: ApplicationState) =>
    state.authenticate.data && state.authenticate.data.Meta.Email ? state.authenticate.data.Meta.Email : undefined;

export const getCompanyUserId = (state: ApplicationState) =>
    state.authenticate.data && state.authenticate.data.Meta ? state.authenticate.data.Meta?.CompanyUserId : undefined;

// export default reduceReducers<any>(authSlice.getInitialState(), authSlice.reducer);

export default authSlice;
