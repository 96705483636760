export type LanguageCode = 'sv' | 'en' | 'no' | 'fi';
export type CountryCode = 'SE' | 'NO' | 'FI';
export type FullLanguageCode = 'sv-SE' | 'en-GB' | 'nb-NO' | 'fi-FI';

export const getXlanguageCode = () => {
    const language = getCurrentLanguageCode();
    
    if(language === 'no') return 'nb-no';

    return language
}

export const getCurrentLanguageCode = (): LanguageCode =>
    (window.localStorage.getItem('language') as LanguageCode) || 'sv';

export const getCurrentCountryCode = (): CountryCode => {
    const languageCode = getCurrentLanguageCode();

    switch (languageCode) {
        case 'sv':
            return 'SE';
        case 'no':
            return 'NO';
        case 'fi':
            return 'FI';
        default:
            return 'SE';
    }
};

export const getFullLanguageCode = (languageCode: string): FullLanguageCode => {
    switch (languageCode) {
        case 'sv':
            return 'sv-SE';
        case 'no':
            return 'nb-NO';
        case 'fi':
            return 'fi-FI';
        case 'en':
            return 'en-GB'
        default:
            return 'sv-SE';
    }
};

export const getShortLanguageCode = (languageCode: string): "sv" | "no" | "fi" | "en" | undefined => {
    switch (languageCode) {
        case 'sv-SE':
            return 'sv';
        case 'nb-NO':
            return 'no';
        case 'fi-FI':
            return 'fi';
        case 'en-GB':
            return 'en'
    }
};
