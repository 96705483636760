import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal as _Modal } from 'semantic-ui-react';
import * as actions from '@/store/actions';

class Modal extends _Modal {
    componentDidMount() {
        const action = this.state.open ? actions.SHOW_MODAL : actions.HIDE_MODAL;
        this.props.dispatch(action());
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if(prevState.open !== this.state.open) {
            const action = this.state.open ? actions.SHOW_MODAL : actions.HIDE_MODAL;
            this.props.dispatch(action());
        }
    }
    componentWillUnmount() {
        this.props.dispatch(actions.HIDE_MODAL());
    }
}

const { Actions, Content, Description, Dimmer, Header  } = _Modal;

export { Actions, Content, Description, Dimmer, Header }  

const connector = connect(null);
export default connector(Modal)