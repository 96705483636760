import React, { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Confirm as SemanticConfirm, Button } from 'semantic-ui-react';
import Modal from '@/components/Modal';
import { CONFIRM_YES, CONFIRM_NO } from '../store/actions';
import useTranslate from '../hooks/useTranslate';

export const Confirm: FC = (props) => {
    const { translate } = useTranslate();

    const { open, message, meta } = useSelector(({ confirm: { open, message, meta } }: any) => ({
        open,
        message,
        meta
    }));
    const danger = meta?.danger;
    const justOk = meta?.justOk;
    
    const dispatch = useDispatch();

    const handleCancel = useCallback(() => {
        dispatch({ type: CONFIRM_NO });
    }, [dispatch]);

    const handleConfirm = useCallback(() => {
        dispatch({ type: CONFIRM_YES });
    }, [dispatch]);

    return justOk ? (
        <Modal open size="mini" style={{ textAlign: 'center' }} onClose={handleCancel}>
            <Modal.Content>
                <div
                    dangerouslySetInnerHTML={{ __html: message || translate('common.areYouSure') }}
                />
            </Modal.Content>
            <Modal.Actions style={{ textAlign: 'center' }}>
                <Button content={translate('form.ok')} onClick={handleConfirm} />
            </Modal.Actions>
        </Modal>
    ) : (
        <SemanticConfirm
            confirmButton={
                danger ? <Button className="danger">{translate('form.ok')}</Button> : undefined
            }
            open={open}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            cancelButton={translate('form.cancel')}
            content={
                <Modal.Content>
                    <div dangerouslySetInnerHTML={{ __html: message || translate('common.areYouSure') }} />
                </Modal.Content>
            }
            size="mini"
        />
    );
};

export default Confirm;
