import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';

import Form from '@/components/Form';
import Loader from '@/components/Loader';
import * as actions from '@/store/actions';

import WebhookSettingsForm from './WebhookSettingsForm';
import { ApplicationState } from '@/store';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends LocalizeContextProps, RouteComponentProps, ConnectedProps<typeof connector> {

}

const MailchimpSettingsLoader: React.FC<Props> = (props) => {
    const { webhookSettings, translate, history } = props;

    return (    
        <Loader
            requirements={[
                {
                    action: actions.FETCH_WEBHOOK_SETTINGS.request(),
                    data: webhookSettings,
                },
            ]}
        >
            <Form
                name="webhookSettings"
                initialValues={webhookSettings.data}
                action={actions.EDIT_WEBHOOK_SETTINGS}
                onCancel={() => props.dispatch(push('/dashboard'))}
                confirmOnLeave={true}
            >
                {(formProps: any) => <WebhookSettingsForm {...formProps} {...props} />}
            </Form>
        </Loader>
    );
};

const connector = connect((state: ApplicationState) => {
    return {
        webhookSettings: state.webhookSettings,
    };
});

export default connector(withLocalize(MailchimpSettingsLoader));
