import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Button } from 'semantic-ui-react';
import Modal from '@/components/Modal';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import * as actions from '@/store/actions';
import { AgreementModalData } from '@/types';
import * as DOMPurify from 'dompurify';


interface Props extends LocalizeContextProps {
    dispatch: Dispatch;
    data: AgreementModalData;
    userId: string;
    agreementId: string;
}

interface State {
}

class AgreementModal extends Component<Props, State> {

    handleNo = () => {
        this.props.dispatch(actions.authLogout());
    };

    hendleAccept = () => {
        const { userId, agreementId } = this.props;
        this.props.dispatch({ type: actions.ACCEPT_AGREEMENT, payload: { userId, agreementId } });
    }

    render() {
        const { data, translate } = this.props;

        return (
            <Modal open size="large" closeOnDimmerClick={false}>
                <Header
                    icon="warning"
                    content={translate('agreements.title')}
                    subheader={translate('agreements.description')}
                    style={{ borderBottom: 'none' }}
                />
                <Modal.Content scrolling style={{ border: '1px solid #E2E8F0', borderRadius: '4px', padding: '30px', width: 'calc(100% - 40px)', margin: '0px auto 20px auto' }}>
                    <div
                        
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content) }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button style={{ float: 'left' }} onClick={this.handleNo}>
                        {translate('agreements.notAccept')}
                    </Button>
                    <Button
                        primary
                        content={translate('agreements.iAccept')}
                        onClick={this.hendleAccept}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        userId: state.authenticate.data.UserId,
        agreementId: state.agreements.modal.data.agreementId,
        data: state.agreements.modal.data,
    };
};

export default connect(mapStateToProps)(withLocalize(AgreementModal));
