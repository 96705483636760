import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import Modal from '@/components/Modal';
import * as DOMPurify from 'dompurify';

import DataTable from '@/components/DataTable';
import * as actions from '@/store/actions';
import { format } from '@/utils/datetime';
import { Newsletter, ListState } from '@/types';
import { LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

const newsletterTypeRenderer = (row: Newsletter) => (
    <Icon name={row.SendMethodId === 1 ? 'envelope' : 'mobile'} />
);
const createDateRenderer = (row: Newsletter) => format(row.Created, 'PPp');
const dateToSendRenderer = (row: Newsletter) => format(row.ToSendDate, 'PPp');

interface NewsletterViewModalProps {
    newsletter: Newsletter;
    onClose: () => void;
}

const NewsletterViewModal: React.FC<NewsletterViewModalProps> = ({ newsletter, onClose }) => (
    <Modal open onClose={onClose} closeIcon size="large">
        <Modal.Content>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsletter.MessageBody) }} />
        </Modal.Content>
    </Modal>
);

interface Props extends LocalizeContextProps {
    newsletters: ListState<Newsletter>;
    dispatch: Dispatch;
    pageSize: number;
}

interface State {
    newsletterToView?: Newsletter;
}

class NewsletterLog extends Component<Props, State> {
    state = {
        newsletterToView: undefined,
    };

    closeNewsletterViewModal = () => {
        this.setState({ newsletterToView: undefined });
    };

    render() {
        const { newsletters, translate } = this.props;
        const { total } = newsletters;
        const { newsletterToView } = this.state;

        const newslettersDataExtended = newsletters.data.map(newsletter => {
            if(!newsletter.Sent) {
                return {...newsletter, hide: translate('newsletterLog.View')};
            }
            return newsletter;
        });

        const columns = [
            { name: '', renderer: newsletterTypeRenderer },
            {
                name: translate('newsletterLog.Created'),
                renderer: createDateRenderer,
            },
            {
                name: translate('newsletterLog.Receiver'),
                attribute: 'Receiver',
            },
            {
                name: translate('newsletterLog.Title'),
                attribute: 'MessageTitle',
            },
            {
                name: translate('newsletterLog.Sent'),
                attribute: 'Sent',
                type: 'boolean',
            },
            {
                name: translate('newsletterLog.ToSendDate'),
                renderer: dateToSendRenderer,
            },
        ];

        const rowActions = [
            {
                name: translate('newsletterLog.Resend'),
                icon: 'redo alternate' as SemanticICONS,
                onAction: ({ Id }: Newsletter) => {
                    this.props.dispatch(actions.RESEND_NEWSLETTER.request({ Id }));
                },
            },
            {
                name: translate('newsletterLog.View'),
                icon: 'eye' as SemanticICONS,
                onAction: (row: Newsletter) => {
                    this.setState({ newsletterToView: row });
                },
            },
        ];

        return (
            <>
                {newsletterToView && (
                    <NewsletterViewModal
                        newsletter={newsletterToView!}
                        onClose={this.closeNewsletterViewModal}
                    />
                )}
                <DataTable
                    loading={newsletters.isLoading}
                    data={newslettersDataExtended}
                    columns={columns}
                    actions={rowActions}
                    pageSize={this.props.pageSize}
                    total={total}
                />
            </>
        );
    }
}

export default NewsletterLog;
