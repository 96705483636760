import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Checkbox, Label, Loader as Spinner } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import DataTable, { Action } from '@/components/DataTable';
import { useGetWebhookEndpointsQuery } from '@/services/bmApi';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getCompanyId } from '@/store/reducers/newAuth';
import useTranslate from '@/hooks/useTranslate';
import { WebhookEndpoint } from '@/types';


const SystemSetttingsForm: React.FC = (props) => {
    const { translate } = useTranslate();
    const companyId = useAppSelector(getCompanyId);
    const dispatch = useAppDispatch();
    const endpointsData = useGetWebhookEndpointsQuery({ CompanyId: companyId });

    const Enabled = (row: WebhookEndpoint) => {
        return <Checkbox readOnly disabled checked={!row.Disabled}/>
    };

    const EventTypes = (row: WebhookEndpoint) => {
        return row.EventTypes ? row.EventTypes.map(evt => (<Label content={evt} />)) : null;
    };

    const columns = [
        {
            name: translate('webhooks.endpoints.enabled.text'),
            renderer: Enabled,
        },
        {
            name: translate('webhooks.endpoints.url.text'),
            attribute: 'Url',
            type: 'text',
        },
        {
            name: translate('webhooks.endpoints.description.text'),
            attribute: 'Description',
            type: 'text',
        },
        {
            name: translate('webhooks.endpoints.eventTypes.text'),
            renderer: EventTypes,
        },
    ];

    const rowActions: Action[] = [
        {
            name: translate('common.edit'),
            icon: 'edit',
            color: 'blue',
            onAction: (webhook: any) => {
                dispatch(push(`/webhooks/endpoint/${webhook.Id}`));
            },
        },
    ];

    
    return (
        <>
            {endpointsData.isFetching ? <Spinner data-testid="loader" active /> : null}
            <DataTable
                data={endpointsData.data?.Endpoints}
                columns={columns}
                actions={rowActions}
            />
        </>
    );
};

export default withLocalize(SystemSetttingsForm);
